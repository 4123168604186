import React,{useState} from 'react'
import {NavLink,Link} from 'react-router-dom'
import {RiCloseLine} from 'react-icons/ri'
import {RiMenu3Line} from 'react-icons/ri'
import {AiFillHome,AiFillPhone} from 'react-icons/ai'
import {IoMdContact,IoIosContacts} from 'react-icons/io'
import Logo from '../assets/feni.svg'
import MobileLogo from '../assets/feni.svg'
import './NavBar.css'
const NavBar = () => {
    const [toggleMenu,setToggleMenu]=useState()
    const [show,setShow]=useState(false)
    const showHandle=()=>{
        if(show===true){
            setShow(false)
        }else{
            setShow(true)
        }
    }
    

    return (
        <div className="main-navigation">
            <div className="menu-navigation-logo-list-buttons">
                <Link to="/">  <div className="logo" >
                   <img src={Logo} alt=""/>
                  </div></Link>
                  <div className="nav-list">
                      
                     <NavLink to="/"> <p>Home</p></NavLink>
                     <NavLink to="/services"> <p>Services</p></NavLink>
                  
                     <NavLink to="/about-us"> <p>About Us</p></NavLink>
                     <NavLink to="/contact-us"> <p>Contact</p></NavLink>
                  </div>
                  <div className="buttons">
                    <a href="https://fernandomolebatsi.substack.com/publish">  <button>Sign Up fo Newsletter</button></a>
                  </div>
            </div>
            <div className="mobile-menu">
                <div className="mobile-nav-button">
                    <div className="mobile-logo">
                       <Link to="/"> <img src={MobileLogo} alt="logo"/></Link>
                    </div>
                    {toggleMenu?
                    <RiCloseLine
                     size={38}
                     color="red"
                     style={{
                         position:"absolute",
                         top:"3%",
                         right:"10px"
                     }}
                     onClick={()=>setToggleMenu(false)}
                    />
                    :<RiMenu3Line
                    style={{
                        position:"absolute",
                        top:"3%",
                        right:"10px"
                    }}
                    size={38}
                     color="red"
                     onClick={()=>setToggleMenu(true)}
                    />
                
                }
                {
                    toggleMenu &&(
                        <div className="list-menu-main">
                        <div className="nav-list-mobile">
                   <Link to="/">     <AiFillHome
                      color="#ff2526"
                      size={28}
                      style={{
                        position:"absolute",
                        top:"3%",
                        right:"105px",
                        cursor:"pointer"
                      }}
                      /></Link>
                            <NavLink to="/"> <p>Home</p></NavLink>
                          
                            <NavLink to="/services"> <p>Services</p></NavLink>
                   <Link to="/services">      <IoIosContacts
                          color="#ff2526"
                          size={28}
                          style={{
                            position:"absolute",
                            top:"30%",
                            right:"110px",
                            cursor:"pointer"
                          }}
                         /></Link>
                            <NavLink to="/about-us"> <p>About Us</p></NavLink>
                         <Link to="/about-us">   <IoMdContact
                             color="#ff2526"
                             size={28}
                             style={{
                                position:"absolute",
                                top:"52%",
                                right:"113px",
                                cursor:"pointer"
                              }}
                            
                            /></Link>
                        <Link to="/contact-us">    <AiFillPhone
                              color="#ff2526"
                              size={28}
                              style={{
                                 position:"absolute",
                                 top:"75%",
                                 right:"110px",
                                 cursor:"pointer"
                               }}
                            /></Link>
                            <NavLink to="/contact-us"> <p>Contact</p></NavLink>
                         </div>
                        </div>
                    )
                }
              
            
                </div>
            </div>
        </div>
    )
}

export default NavBar
