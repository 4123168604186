import React from 'react'
import './Vision.css'
import Project from '../assets/undraw_landscape_photographer_156c.svg'
import Educator from '../assets/undraw_educator_re_ju47.svg'
import Decision from '../assets/undraw_informed_decision_p2lh.svg'
import Guide from '../assets/undraw_male_avatar_g98d.svg'
const Vision = () => {
    return (
        <div className="our-vision">
            <h1>Our Belief System</h1>
            <div className="vision-plus-strategy">
                <div className="vision">
                    <img src={Project} alt="our-vision"/>
                    <div className="vision-contents">
                        <h3> Mission Statement</h3>
                        <p>To be the premier choice for corporate,both government and non governmental 
                            organizations and individuals at large.To provide all 
                            our clients justly with first class services.
                            </p>
                    </div>
                </div>
                <div className="mission">
                    <img src={Decision} alt="our-mission"/>
                    <div className="mission-contents">
                        <h3>Vision Statement</h3>
                        <p>To be a leading company to benchmark from in the consultancy(Taxation 
                            and company secretarial services),thereby providing effective 
                            and efficient integrated special solutions.
                            </p>
                    </div>
                </div>
            </div>
            <div className="objective-plus-strategy">
                <div className="objective">
                    <img src={Educator} alt="our-objective"/>
                    <div className="objective-contents">
                        <h3>Our Standards and  Beliefs</h3>
                        <p>Fern Field consultants strongly believes high quality service system,
                            necessary individuals strength synergy plus goal orientation and service delivery.
                            </p>
                    </div>
                </div>
                <div className="strategy">
                    <img src={Guide} alt="our-strategy"/>
                    <div className="strategy-contents">
                        <h3>Directors Background </h3>
                        <p>The company director Mr Fernando Oarabile Molebatsi is a citizen of Botswana 
                            who has a Bachelor of Commerce in Accounting.
                          
                            </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vision
