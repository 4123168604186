import React from 'react'
import Contact from '../Components/Contact'
import Address from '../Components/Address'
const ContactScreen = () => {
    return (
        <div>
            <Contact/>
            <Address/>
        </div>
    )
}

export default ContactScreen
