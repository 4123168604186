import React from 'react'
import View from '../Components/View'
import Offers from '../Components/Offers'
import Indicator from '../Components/Indicator'
import Appointment from '../Components/Appointment'
import Tab from '../Components/Tab'
import Community from '../Components/Community'
import Chat from '../Components/Chat'
const HomeScreen = () => {
    return (
        <div>
            <View/>
            <Chat/>
            <Offers/>
         
  <Indicator/>
  <Appointment/>
  <Tab/>
  <Community/>
        </div>
    )
}

export default HomeScreen
