import React from 'react'
import {Link} from 'react-router-dom'
import './Address.css'
import Social from '../assets/undraw_interview_re_e5jn.svg'
const Address = () => {
    return (
        <div className="physical-address">
              <h1>Personal Contact</h1>
              <p className="limit">Our emphasis is not just to do business with you but rather build 
                        a relationship.We yearn to meet you in person and find amicable solutions together.</p>
            <div className="physical">
            
                <div className="physical-contacts">
                    <h3>Our physical address</h3>
                
                    <p>Visit us on lot 1611,Metlhabeng ward,Tlokweng ,we wait to talk with you.</p>
                       <Link to="/">  <button>Book Appointment</button></Link>
                </div>
                <img src={Social} alt="contacts"/>

            </div>
        </div>
    )
}

export default Address
