import React from 'react'
import Budget from '../assets/undraw_Business_analytics_re_tfh3 (1).png'
import Finance from '../assets/undraw_Business_chat_re_gg4h.png'
import Analysis from '../assets/undraw_Finance_re_gnv2 (1).png'
import './Offers.css'
const Offers = () => {
    return (
        <div className="services-offered">
            <h1 > <span className="red">What we offer? </span></h1>
            <div className="services-images-text">
                <div className="services-one">
                    <img src={Budget} alt="budgeting"/>
                    <h2>Tax Advisory</h2>
                    <p>Advising clients to be tax compliant and encourage planning.</p>
                </div>
                <div className="services-one">
                    <img src={Finance} alt="finance"/>
                    <h2>PAYE(Pay As You Earn)</h2>
                    <p>Filling of monthly and annual returns.</p>
                </div>
                <div className="services-one">
                    <img src={Analysis} alt="analytics"/>
                    <h2>VAT(Value Added Tax)</h2>
                    <p>Filling of VAT returns and attend to queries of clients.</p>
                </div>
            </div>
        </div>
    )
}

export default Offers
