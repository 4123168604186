import React from 'react'
import whatsApp from '../assets/whatsapp-logo-png-2263 copy 2.png'
import './Chat.css'
const Chat = () => {
    return (
        <div className="position-center">
        <div className="whatsApp">
        <a href=" https://wa.me/416954483"><img  src={whatsApp} alt="app"/></a>
        </div>
       
    </div>
    )
}

export default Chat
