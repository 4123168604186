import React from 'react'
import './Contact.css'
import People from '../assets/people-2591874_1920.jpg'
const Contact = () => {
    return (
        <div className="service-background">
            <div className="lay-over"></div>
            <img src={People} alt=""/>
            <div className="contents">
                <h1> BUILDING RELATIONSHIPS</h1>
                <p>We believe in developing a growth mindset,that as we both grow in business,we improve each other.
                    </p>
            </div>
        </div>
    )
}

export default Contact
