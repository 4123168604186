import React,{useRef} from 'react'
import {BsCheckCircleFill} from 'react-icons/bs'
import './Appointment.css'
import emailjs from '@emailjs/browser';
const Appointment = () => {
    const form=useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_255uq8g', 'template_ehpxyk7', form.current, 'fgbeg8w5dN-ihQ1X1')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };
   
    
    return (
        <div className="contact-form-with-both-sides">
            <div className="sub-contact-form-div">
                <div className="the-detail-side-div">
                    <h3>Book an Appointment</h3>
              <BsCheckCircleFill
              size={58}
              color="white"
              className="margins"
              style={{marginLeft:"200px"}}
              />
              <h2>Fern Field Consultants </h2>
              <p>Welcome to next level Secretarial services,where we kickstart your corporate 
                  journey and financial freedom.
                  
              </p>
            
                </div>
                
                <div className="sub-contact-the-form-side">
                    <h3>Appointments Here</h3>
                    <form ref={form}  onSubmit={sendEmail}>
                        <label>Full Name</label>
                        <input type="text" 
                        name="user_name"
                        
                        placeholder="your name"/>
                         <label> Service required</label>
                        <input type="text" 
                          name="subject"
                      
                        placeholder="service"/>
                        <label> Contact Number</label>
                        <input type="text" 
                        name="user_contact"
                        placeholder="your phone number"/>
                        <label> Email</label>
                        <input type="email" 
                        name="user_email"
                        placeholder="your email"/>
                       <label>Book a service</label>
                       <select name="user_data" >
                           <option >Company Registration(Business Registration)</option>
                           <option >Business Plans</option>
                           <option >Financial Advisory</option>
                           <option >Taxation</option>
                           <option >Public Procurement Regulatory Authority</option>
                       </select>
                    <button type="submit">Book</button>
                    </form>
                   
                </div>
            </div>
        </div>
    )
}

export default Appointment