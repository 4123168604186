import React from 'react'
import './Training.css'
import Workshop from '../assets/meeting-1453895_640.png'
import Pitch from '../assets/presentation-1454403_640.png'
const Training = () => {
    return (
        <div className="training">
            <h2>We can really help you</h2>
            <div className="corporate">
                <img src={Workshop} alt="training"/>
                <div className="cut-half">
                <h1>Let us train your organization</h1>
                <p>
                   We are qualified,we can train your organization 
                   or department to be up to-date and evolve in ways of dealing with finance and issues with taxation on tactical level 
                   as well as individual level.
                </p>
                <p>
                   Arrange of short courses and seminars that can take a weekend or two but 
                   with a life time benefit that not only elevates your 
                   organization performance but also makes you free from other liabilities.
                </p>
                </div>

            </div>
            <div className="corporate-one">
              <div className="cut-half-one">
              <h1>Individual Trainings</h1>
                <p>
                    Employees or business owners who personally want to understand how the financial cycle works 
                    and preparation of financial documents such as Income statements,Statements of Financial Positions,Cash flows and Tax payments,we can teach you.
                </p>
                <p>
                    The future begins today on how you prepare for it financially.
                    Success and financial freedom is not an accident or luck,rather
                     its a plan and a habit.We are here to propel you to that destiny.
                </p>
              </div>
               
                <img src={Pitch} alt="training"/>
            </div>
        </div>
    )
}

export default Training
