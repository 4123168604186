import React from 'react'
import './Benefits.css'
import Gains from '../assets/coins-1857222_640.png'
const Benefits = () => {
    return (
        <div className="benefits">
            <h1>Offering the best in class</h1>
            <div className="what-you-get">
                <div className="lists">
                    <h2>Our benefits to you</h2>
                    <ul className="the-lists">
                        <li>Full 24 hr support</li>
                        <li>Professional care and availability</li>
                        <li>Comprehensive advisory</li>
                        <li>Affordable with less risk of failure</li>
                    </ul>
                </div>
                <img src={Gains} alt="benefits"/>
            </div>
        </div>
    )
}

export default Benefits
