import React from 'react'
import Service from '../Components/Service'
import Packages from '../Components/Packages'
import Training from '../Components/Training'
const ServiceScreen = () => {
    return (
        <div>
            <Service/>
           
            <Packages/>
            <Training/>
        </div>
    )
}

export default ServiceScreen
