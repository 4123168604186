import React from 'react'
import './Indicator.css'
const Indicator = () => {
    return (
        <div className="indications">
            <div className="indications-process">
            <div className="process">
                <div className="circle-one">1</div>
                <div className="vl"></div>
                <div className="circle-one">2</div>
                <div className="vl"></div>
                <div className="circle-one">3</div>
                <div className="vl"></div>
                <div className="circle-one">4</div>
                <div className="vl"></div>
            </div>
            <div className="process-text">
                <div className="process-text-one">
                <h3>Company Registration Process</h3>
                <p>The legitimacy to check if your ideal company name is taken or not.
                    We will help you come with meaningful name and
                     reserve it with CIPA as you start the process.
                </p>
                <p>After your name is reserved and is free to use,the preferred name will be register by CIPA
                    as your company name and then you start incorporation process.
                </p>
  
              
                </div>
                <div className="process-text-two">
                <h3>Appointment of Company Secretary</h3>
                <p>As we are eligible to be your company secretary through our accreditation process.The tough
                    parts of having your accounts in check,share holding,annual tax returns,when you register with us.We will 
                    do all the heavy dirty tricky stuff.
                </p>
                <p>This include drafting and allocating your shares with other investors 
                    in your company to spread that limited liability with your co-owners.
                    Fern Field Consultants will prepare the certificate and give guidance on right procedures of share holding.
                </p>
             
                </div>
                <div className="process-text-three">
                <h3>Bank Account Opening</h3>
                   <p>All registered and credible companies must have official bank accounts to do ethical transactions with
                    .If you get our package and choose us,we will do this tough task with ease and smoothness.
                </p>
                
                <p>It is financially palatable to have a bank account as this has a lot of advantages on different fronts. 
                    Your company credit level or debt level will be reflected through your bank transactions.In case you need financial 
                    assistance from banks,venture capitalists or mere investors,for trust,your accounts activity gives you leeway.
                </p>
 
                </div>
                <div className="process-text-four">
                <h3>Tax Registration</h3>
                <p>When you have your bank account and want to be ethical with BURS,its not even a thought,its a must 
                    must to register for taxation so that you pay as required and not be found owing by tax authorities. 
                    At Fern Field Consultants we will help you get started with this process and do the heavy lifting for you. 
                </p>
            
                </div>
               
            </div>
            </div>
       
        </div>
    )
}

export default Indicator
