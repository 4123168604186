import React from 'react'
import './Service.css'
import Meeting from '../assets/office-1966381_1920.jpg'
const Service = () => {
    return (
        <div className="service-background">
            <div className="lay-over"></div>
            <img src={Meeting} alt=""/>
            <div className="contents">
                <h1> FINANCIAL ADVICE</h1>
                <p>Learn more about finance,the more you avoid debt and fear of tax.</p>
            </div>
        </div>
    )
}

export default Service
