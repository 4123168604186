import React from 'react'
import './Tab.css'
import Tax from '../assets/documents-158461_1280.png'
import Income from '../assets/school-1555910_1280.png'
import Budget from '../assets/calculator-23414_1280.png'
import Penny from '../assets/tax-7630744_1280.png'
const Tab = () => {
    return (
        <div className="flex-tab">
            <h1><span className="underLiner">What you need to know</span></h1>
            <div className="tab-images">
                <div className="tabs-one">
                <img src={Tax} alt="services" />
                <div className="tabs-text-one">
                <h4>Tax Returns</h4>
                <p>
                    Filing and reporting  your expenses,incomes and
                     other relevant financial information to tax authorities.
                </p>
                </div>
              
                </div>
                <div className="tabs-two">
                <img src={Income} alt="services"/>
                <div className="tabs-text-one">
                <h4>Corporate Income Tax(CIT)</h4>
                <p>This is tax that is charged on the annual income of a 
                    company.Companies that made losses do not get charged income tax.
                </p>
                </div>
                </div>
                </div>
                <div className="tab-images-two">
                <div className="tabs-three">
                <img src={Penny} alt="services "/>
                <div className="tabs-text-one">
                <h4>Individual Income Tax(IIT)</h4>
          <p>Tax levied on Gross remuneration after deducting exempt income.Tax tables are used 
              to calculate the amount of that to charged.Do not evade tax rather engage us to help you to avoid it.
                </p>
                </div>
            
               
                </div>
                <div className="tabs-four">
                <img src={Budget} alt="services "/>
                <div className="tabs-text-one">
                <h4>Tax Clearance Certificate(TCC)</h4>
                <p>Helping your business being up to date with tax
                     authorities and getting the clearance that you are not owing.
                </p>
                </div>
              
               
                </div>
                </div>
               
                 
           
        </div>
    )
}

export default Tab
