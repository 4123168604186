import React from 'react'
import {Link} from 'react-router-dom'
import './View.css'
import Office from '../assets/earth-1303628_1280.png'
const View = () => {
   
  
    
    return (
        <div className="view-section">
            <div className="overlay"></div>
            <div className="view-both-text-image">
                <div className="text-plus-buttons">
                    <h1><span className="fern">Fern Field Consultants</span>  Your Trusted Business Experts.</h1>
                    <h4>Liberating you with Financial huddles</h4>
                    <p>BOTS GIVERS INVESTMENTS (PTY) LTD trading as FERN FIELD CONSULTANTS
                        is a 100% youth owned citizen company that specializes in consultancy,with much
                        emphasis on Taxation and Secretarial services. 
                      </p>
                        <p>Bots Givers Investments (Pty) Ltd trading as Fern Field Consultants is a member of Association of Business consultants 
                            of Botswana.It has qualified to act as a company secretary under section 162(3)(b) of the 
                            companies Act 42:01.
                        </p>
                       <a href="mailto:fernandomolebs@gmail.com"> <button className="appoint">Email Us</button></a>
                      <a href="https://www.facebook.com/search/top?q=fern%20field%20consultants">  <button className="fb">Talk to us on Facebook</button></a>
                </div>
                <div className="image">
                <img src={Office} alt="office"/>
             <Link to="/services">   <button 
        
                className="appoint-2">View more of our services</button></Link>
                </div>
               
            </div>
        </div>
    )
    }

export default View
