import React from 'react'
import './About.css'
import Meeting from '../assets/receptionists-5975962_1920.jpg'
const About = () => {
    return (
        <div className="service-background">
        <div className="lay-over"></div>
        <img src={Meeting} alt=""/>
        <div className="contents">
            <h1> CLIENTS VALUE</h1>
            <p>High quality service system,maintaining high standards of excellence.</p>
        </div>
    </div>
    )
}

export default About
