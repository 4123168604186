import React from 'react'
import './Packages.css'
import {Link} from 'react-router-dom'
import Pay from '../assets/dollars-31085_1280.png'
import Coins from '../assets/coins-1857222_640.png'
import Cash from '../assets/money-33202_1280.png'
const Packages = () => {
    return (
        <div className="payment_cards">
            <h1 className="header-one">Our in most demand services</h1>
            <div className="payment_cards_three">
                <div className="igsce">
                   <h2>Tax & Income Returns</h2>
                   <img src={Pay} alt="igsce"/>
                  
                    <h1 className="enroll">Dealing with Tax Returns</h1>
                    
                     <p>Book an appointment and we will help you understand
                         and go through the process of legitimately paying your tax.
                     </p>
                     <p>Benefits of doing tax returns are just beyond compliance
                         but also gives you an advantage to access funds by looking at 
                         your records.
                     </p>
                <Link to="/">    <button>Book Now</button></Link>
                </div>
                <div className="bgsce">
                <h2>Secretarial Services</h2>
                   <img src={Coins} alt="igsce"/>
                 
                    <h1 className="enroll">Company Registration</h1>
                   
                    <p>The process of incorporating a company can be 
                        overwhelming and cumbersome,here we make it less complex as it is.We are fast 
                        ,efficient and reliable to give you a company of your dreams as quick as possible. 
                     </p>
                     <p>When you are in business with us we make the process easier and we will keep the 
                         records update and make sure you have good books.
                     </p>
  
                     <p>Get in touch with our team of professionals who have vast experience and delivered 
                         to countless clients. 
                     </p>
 
                   <Link to="/"> <button>Book Now</button></Link>
                    </div>
                    <div className="cambridge">
                    <h2>Business Plans</h2>
                   <img src={Cash} alt="igsce"/>
                   
                    <h1 className="enroll">Get plans for business</h1>
                    <p>Lets make your youth grant application easy and worthwhile with our expertise 
                        in creating detailed,precise and visionary business plans.
                     </p>
                     <p>If you are looking for investment from different people or institutions we will deliver as you imagined.
                     </p>
                  <Link to="/">   <button>Book Now</button></Link>
                    
                    </div>
            </div>
            
        </div>
    )
}

export default Packages
