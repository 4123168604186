import React from 'react'
import './Community.css'
import Iphone from '../assets/iphone-37856_1280.png'
const Community = () => {
    return (
        <div className="community">
            <h1>Join our community</h1>
            <div className="image-text-button">
                <div className="image-plus-button">
                       <img src={Iphone} alt=" phone"/>
                       
                </div>
                <div className="words">
                    <h2>Get your fear off,you can be accountable.</h2>
                    <p>Subscribe to our newsletter and get promotional 
                        codes,and participate in financial conversations on our forum.We take a deep dive in all 
                        matters regarding business and finance.
                           
                            </p>
                            <p>You are free to unsubscribe any time if you do not like 
                                the content and the education going on.But we promise
                                 you that you will learn a lot and these 
                                small tweaks will tend to be marginal gains over time.
                           
                            </p>
                            <a href="https://fernandomolebatsi.substack.com/publish">   <button>Join Newsletter</button></a>
                </div>
            </div>
        </div>
    )
}

export default Community
