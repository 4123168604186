import React from 'react'
import './Footer.css'
import {Link} from 'react-router-dom'
import Image from '../assets/feni.svg'
const Footer = () => {
    return    <div className="footer-main">
    <h1 style={{textAlign:"center",color:"white"}}>CONTACT US FOR ANY DETAILS</h1>
    <div className="footer-contents-main">
        <img src={Image} alt="office"/>
        <div className="lists-for-footer">
            <h3>Our Services</h3>
            <ul className="lists">
               <Link><li>Business Plans</li></Link>
               <Link><li>Financial Advisory</li></Link>
               <Link><li>Taxation</li></Link>
               <Link><li>Company Registration</li></Link>
               <Link><li>Public Procurement Regulatory</li></Link>
            </ul>
        </div>
        <div className="lists-for-footer">
          

            <h3>Contacts & Support</h3>
            <ul className="lists">
               <li>Call Us:</li>
               <Link><li>+267 76079245</li></Link>
               <li>Email Us:</li>
               <Link><li>fernfieldconsultants@gmail.com</li></Link>
            </ul>
        </div>
        <div className="lists-for-footer">
            <h3>Working Hours</h3>
            <ul className="lists">
         
               <li>Monday-Friday</li>
               <Link><li>8:00AM-5:30PM</li></Link>
               <li>Week-Ends</li>
               <Link><li>8:30AM-4:30PM</li></Link>
            </ul>
        </div>
    </div>
</div>

}

export default Footer
