
import NavBar from './Components/NavBar'
import Screen from './Screens/Screen'
import Footer from './Components/Footer'
function App() {
  return (
   <div>
   <NavBar/>
  <Screen/>
   
 
   <Footer/>
   </div>
  );
}

export default App;
