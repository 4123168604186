import React from 'react'
import About from '../Components/About'
import Vision from '../Components/Vision'
import Benefits from '../Components/Benefits'
const AboutScreen = () => {
    return (
        <div>
            <About/>
            <Vision/>
            <Benefits/>
        </div>
    )
}

export default AboutScreen
