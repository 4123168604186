import React from 'react'
import {Routes,Route} from 'react-router-dom'
import AboutScreen from '../Screens/AboutScreen'
import HomeScreen from '../Screens/HomeScreen'
import ServiceScreen from '../Screens/ServiceScreen'
import ContactScreen from '../Screens/ContactScreen'
const Screen = () => {
    return (
        <Routes>
           <Route path="/" element={<HomeScreen/>}/> 
           <Route path="/services" element={<ServiceScreen/>}/>
           <Route path="/about-us" element={<AboutScreen/>}/>
           <Route path="/contact-us" element={<ContactScreen/>}/>
        </Routes>
    )
}

export default Screen
